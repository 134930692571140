import React from "react";
import { Route, Redirect } from "react-router";
import loadable from "@loadable/component";
import { LANDSCAPE_DEFAULTS } from "./helpers/defaults";

const fallback = <div className="page-progress-bar" />;

const BaseAsync = loadable(() => import("./components/base/base"), { fallback });
const HomeAsync = loadable(() => import("./components/home/home"), { fallback });
const NotFoundAsync = loadable(() => import("./components/base/404.js"), { fallback });

const CaseListAsync = loadable(() => import("./components/ptab/case-list/case-list"), { fallback });
const CasesAsync = loadable(() => import("./components/ptab/cases"), { fallback });
const AnalyticsAsync = loadable(() => import("./components/ptab/analytics/analytics"), { fallback });
const PTABAnnualReportAsync = loadable(() => import("./components/ptab/annual-report/annual-report"), { fallback });
const CaseAsync = loadable(() => import("./components/ptab/case/case"), { fallback });
const JudgeAsync = loadable(() => import("./components/ptab/judge/detail"), { fallback });

const CaseTabStatusPhaseAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-status-phase"), { fallback });
const CaseTabJudgeAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-judge"), { fallback });
const CaseTabCounselAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-counsel"), { fallback });
const CaseTabSectorAndEntityTypeAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-sector-and-entity-type"), { fallback });
const CaseTabDateAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-date"), { fallback });
const CaseTabTopPartiesAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-top-parties"), { fallback });
const CaseTabISIXAsync = loadable(() => import("./components/ptab/analytics/case-level/tab-isix"), { fallback });
const ClaimTabOutcomeAsync = loadable(() => import("./components/ptab/analytics/claim-level/tab-outcome"), { fallback });
const ClaimTabJudgeAsync = loadable(() => import("./components/ptab/analytics/claim-level/tab-judge"), { fallback });
const ClaimTabCounselAsync = loadable(() => import("./components/ptab/analytics/claim-level/tab-counsel"), { fallback });

const PatentDetailAsync = loadable(() => import("./components/patent/patent-detail/patent-detail"), { fallback });
const PatentSearchAsync = loadable(() => import("./components/patent/search"), { fallback });
const PatentListAsync = loadable(() => import("./components/patent/patent-table"), { fallback });
const PatentAnalyticsAsync = loadable(() => import("./components/patent/analytics/analytics"), { fallback });
const PatentTopAssigneeAsync = loadable(() => import("./components/patent/analytics/top-assignee"), { fallback });
const PatentTopLawfirmAsync = loadable(() => import("./components/patent/analytics/top-lawfirm"), { fallback });
const PatentOverYearAsync = loadable(() => import("./components/patent/analytics/over-year"), { fallback });
const PatentOppositionAsync = loadable(() => import("./components/patent/analytics/opposition"), { fallback });
const PatentRelatedCasesAnalyticsAsync = loadable(() => import("./components/patent/analytics/ptab-and-litigation"), { fallback });

const ProsecutionAsync = loadable(() => import("./components/prosecution/prosecution"), { fallback });
const ProsecutionEntityAsync = loadable(() => import("./components/prosecution/entity-detail"), { fallback });

const Projects = loadable(() => import("./components/portfolio/projects"), { fallback });
const Portfolios = loadable(() => import("./components/portfolio/portfolios"), { fallback });
const PortfolioBBExample = loadable(() => import("./components/portfolio/blackberry-example"), { fallback });
const PriorArtAsync = loadable(() => import("./components/prior-art/prior-art"), { fallback });

const InvalidityContextWrapper = loadable(() => import("./components/invalidity/invalidity-context-wrapper"), { fallback });
const Invalidity = loadable(() => import("./components/invalidity/main"), { fallback });
const InvalidityProjects = loadable(() => import("./components/invalidity/invalidity-projects"), { fallback });

const HEVCLandscapeAsync = loadable(() => import("./components/enterprise/landscape-hevc"), { fallback });
const AVCLandscapeAsync = loadable(() => import("./components/enterprise/landscape-avc"), { fallback });
const LTELandscapeAsync = loadable(() => import("./components/enterprise/landscape-lte"), { fallback });
const LTERANLandscapeAsync = loadable(() => import("./components/enterprise/landscape-lte-ran"), { fallback });
const FiveGLandscapeAsync = loadable(() => import("./components/enterprise/landscape-5g"), { fallback });
const FiveGRANLandscapeAsync = loadable(() => import("./components/enterprise/landscape-5g-ran"), { fallback });
const WIFI6LandscapeAsync = loadable(() => import("./components/enterprise/landscape-wifi6"), { fallback });
const VVCLandscapeAsync = loadable(() => import("./components/enterprise/landscape-vvc"), { fallback });
const HEVCRepoAsync = loadable(() => import("./components/enterprise/repo-hevc"), { fallback });
const Repo3GPPAsync = loadable(() => import("./components/enterprise/repo-3gpp"), { fallback });
const RepoIeeeAsync = loadable(() => import("./components/enterprise/repo-ieee"), { fallback });
const RepoIetfAsync = loadable(() => import("./components/enterprise/repo-ietf"), { fallback });

const LitigationAsync = loadable(() => import("./components/litigation/case-detail"), { fallback });
const LitigationSearchAsync = loadable(() => import("./components/litigation/search"), { fallback });
const LitigationCaseListAsync = loadable(() => import("./components/litigation/case-list"), { fallback });
const LitigationAnalyticsAsync = loadable(() => import("./components/litigation/analytics"), { fallback });
const LitigationAnnualReportAsync = loadable(() => import("./components/litigation/annual-report"), { fallback });

const ReexamSearchAsync = loadable(() => import("./components/re-exam/re-exam"), { fallback });
const ReexamListAsync = loadable(() => import("./components/re-exam/case-list"), { fallback });
const ReexamDetailAsync = loadable(() => import("./components/re-exam/case-detail"), { fallback });
const ReexamAnalyticsAsync = loadable(() => import("./components/re-exam/analytics"), { fallback });

const InternationalSearchAsync = loadable(() => import("./components/international/intl-search"), { fallback });
const InternationalListAsync = loadable(() => import("./components/international/intl-table"), { fallback });
const InternationalAnalyticsAsync = loadable(() => import("./components/international/intl-analytics"), { fallback });

const UPCSearchAsync = loadable(() => import("./components/upc/upc-search"), { fallback });
const UPCListAsync = loadable(() => import("./components/upc/upc-table"), { fallback });
const UPCAnalyticsAsync = loadable(() => import("./components/upc/upc-analytics"), { fallback });
const UPCDetailAsync = loadable(() => import("./components/upc/case-detail/upc-case-detail"), { fallback });

const CompanyAsync = loadable(() => import("./components/company/detail"), { fallback });

const onRouteChange = (prevState, nextState) => {
  // only scroll to top when pathname changes, not when only query strings change
  if (prevState.location.pathname !== nextState.location.pathname) {
    window.scrollTo(0, 0);
  }
};

const AppRoutes = (
  <Route component={BaseAsync} onChange={onRouteChange}>
    <Route component={HomeAsync} path="/" />
    <Route component={CasesAsync}>
      <Route component={CaseListAsync} path="/ptab/caselist" />
      <Route component={AnalyticsAsync}>
        <Route component={CaseTabDateAsync} path="/ptab/analytics/case-level/by-date" />
        <Route component={CaseTabTopPartiesAsync} path="/ptab/analytics/case-level/top-parties" />
        <Route component={CaseTabStatusPhaseAsync} path="/ptab/analytics/case-level/by-status-and-phase" />
        <Route component={CaseTabSectorAndEntityTypeAsync} path="/ptab/analytics/case-level/by-sector-and-entity-type" />
        <Route component={CaseTabISIXAsync} path="/ptab/analytics/case-level/isix" />
        <Route component={CaseTabJudgeAsync} path="/ptab/analytics/case-level/by-judge" />
        <Route component={CaseTabCounselAsync} path="/ptab/analytics/case-level/by-counsel" />
        <Route component={ClaimTabOutcomeAsync} path="/ptab/analytics/claim-level/by-outcome" />
        <Route component={ClaimTabJudgeAsync} path="/ptab/analytics/claim-level/by-judge" />
        <Route component={ClaimTabCounselAsync} path="/ptab/analytics/claim-level/by-counsel" />
      </Route>
    </Route>
    <Route component={PTABAnnualReportAsync} path="/ptab/annual-report" />
    <Route component={CaseAsync} path="/ptab/case/:casenum" />
    <Route component={JudgeAsync} path="/ptab/judge/:name" />
    <Route component={PatentSearchAsync}>
      <Route component={PatentListAsync} path="/patents/search" />
      <Route component={PatentAnalyticsAsync}>
        <Redirect from="/patents/analytics" to="/patents/analytics/top-assignees" />
        <Route component={PatentTopAssigneeAsync} path="/patents/analytics/top-assignees" />
        <Route component={PatentTopLawfirmAsync} path="/patents/analytics/top-law-firms" />
        <Route component={PatentRelatedCasesAnalyticsAsync} path="/patents/analytics/ptab-and-litigation" />
        <Route component={PatentOverYearAsync} path="/patents/analytics/by-date" />
        <Route component={PatentOppositionAsync} path="/patents/analytics/opposition" />
      </Route>
    </Route>
    <Route component={PatentDetailAsync} path="/patents/patent/:patentnum" />
    <Route component={ReexamSearchAsync}>
      <Route component={ReexamListAsync} path="/exparte/search" />
      <Route component={ReexamAnalyticsAsync} path="/exparte/analytics" />
    </Route>
    <Route component={ReexamDetailAsync} path="/exparte/:appnum" />
    <Route component={ProsecutionAsync} path="/prosecution" />
    <Route component={ProsecutionEntityAsync} path="/prosecution/examiner/:name" />
    <Route component={ProsecutionEntityAsync} path="/prosecution/lawfirm/:name" />
    <Route component={ProsecutionEntityAsync} path="/prosecution/artunit/:name" />
    <Redirect from="/patents/examiner" to="/prosecution" />
    <Route component={PriorArtAsync} path="/patents/prior-art/:activetab" />
    <Redirect from="/patents/prior-art" to="/patents/prior-art/pqai" />
    <Route component={Projects}>
      <Route component={Portfolios} path="/patents/portfolio" />
      <Route component={Portfolios} path="/patents/portfolio/my/:project" />
      <Route component={PortfolioBBExample} path="/patents/portfolio/up/bb-example" />
    </Route>
    <Route component={InvalidityContextWrapper}>
      <Route component={InvalidityProjects}>
        <Route component={Invalidity} path="/patents/invalidity" />
        <Route component={Invalidity} path="/patents/invalidity/my/:chartId" />
      </Route>
    </Route>
    <Route component={HEVCLandscapeAsync} path="/standards/opal/hevc/:version" />
    <Redirect from="/standards/opal/hevc" to={`/standards/opal/hevc/${LANDSCAPE_DEFAULTS["hevc"].version}`} query={LANDSCAPE_DEFAULTS["hevc"].filters[LANDSCAPE_DEFAULTS["hevc"].version]} />
    <Route component={AVCLandscapeAsync} path="/standards/opal/avc/:version" />
    <Redirect from="/standards/opal/avc" to={`/standards/opal/avc/${LANDSCAPE_DEFAULTS["avc"].version}`} query={LANDSCAPE_DEFAULTS["avc"].filters[LANDSCAPE_DEFAULTS["avc"].version]} />
    <Route component={LTELandscapeAsync} path="/standards/opal/lte/:version" />
    <Redirect from="/standards/opal/lte" to={`/standards/opal/lte/${LANDSCAPE_DEFAULTS["lte"].version}`} query={LANDSCAPE_DEFAULTS["lte"].filters[LANDSCAPE_DEFAULTS["lte"].version]} />
    <Route component={LTERANLandscapeAsync} path="/standards/opal/lte_ran/:version" />
    <Redirect
      from="/standards/opal/lte_ran"
      to={`/standards/opal/lte_ran/${LANDSCAPE_DEFAULTS["lte_ran"].version}`}
      query={LANDSCAPE_DEFAULTS["lte_ran"].filters[LANDSCAPE_DEFAULTS["lte_ran"].version]}
    />
    <Route component={FiveGLandscapeAsync} path="/standards/opal/5g/:version" />
    <Redirect from="/standards/opal/5g" to={`/standards/opal/5g/${LANDSCAPE_DEFAULTS["5g"].version}`} query={LANDSCAPE_DEFAULTS["5g"].filters[LANDSCAPE_DEFAULTS["5g"].version]} />
    <Route component={FiveGRANLandscapeAsync} path="/standards/opal/5g_ran/:version" />
    <Redirect from="/standards/opal/5g_ran" to={`/standards/opal/5g_ran/${LANDSCAPE_DEFAULTS["5g_ran"].version}`} query={LANDSCAPE_DEFAULTS["5g_ran"].filters[LANDSCAPE_DEFAULTS["5g_ran"].version]} />
    <Route component={WIFI6LandscapeAsync} path="/standards/opal/wifi6/:version" />
    <Redirect from="/standards/opal/wifi6" to={`/standards/opal/wifi6/${LANDSCAPE_DEFAULTS["wifi6"].version}`} query={LANDSCAPE_DEFAULTS["wifi6"].filters[LANDSCAPE_DEFAULTS["wifi6"].version]} />
    <Route component={VVCLandscapeAsync} path="/standards/opal/vvc/:version" />
    <Redirect from="/standards/opal/vvc" to={`/standards/opal/vvc/${LANDSCAPE_DEFAULTS["vvc"].version}`} query={LANDSCAPE_DEFAULTS["vvc"].filters[LANDSCAPE_DEFAULTS["vvc"].version]} />
    <Route component={HEVCRepoAsync} path="/standards/open/mpeg" />
    <Redirect from="/standards/assure/hevc" to="/standards/open/mpeg" />
    <Route component={Repo3GPPAsync} path="/standards/open/3gpp" />
    <Route component={RepoIeeeAsync} path="/standards/open/ieee" />
    <Route component={RepoIetfAsync} path="/standards/open/ietf" />
    <Route component={LitigationSearchAsync}>
      <Route component={LitigationCaseListAsync} path="/litigation/caselist" />
      <Route component={LitigationAnalyticsAsync} path="/litigation/analytics" />
    </Route>
    <Route component={LitigationAsync} path="/litigation/:courtname/case/:casenum" />
    <Redirect from="/litigation/profile/:casenum/:courtname" to="/litigation/:courtname/case/:casenum" />
    <Route component={LitigationAnnualReportAsync} path="/litigation/annual-report" />
    <Route component={InternationalSearchAsync}>
      <Route component={InternationalListAsync} path="/international-challenges/search" />
      <Route component={InternationalAnalyticsAsync} path="/international-challenges/analytics" />
    </Route>
    <Route component={UPCSearchAsync}>
      <Route component={UPCListAsync} path="/upc/search" />
      <Route component={UPCAnalyticsAsync} path="/upc/analytics" />
    </Route>
    <Route component={UPCDetailAsync} path="/upc/case/:casenum" />
    <Route component={CompanyAsync} path="/company/:name" />
    <Route component={NotFoundAsync} path="*" />
  </Route>
);

export default AppRoutes;
